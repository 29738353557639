/* FONT LINKS */
@font-face {
  font-family: Gyahegi;
  src: url("assets/Louis\ George\ Cafe\ Light.ttf");
  font-size: 100%;
}

@font-face {
  font-family: Queensila;
  src: url("assets/Louis\ George\ Cafe\ Light.ttf");
  font-size: 100%;
}

/* COLOR SCHEME */

:root {
  --pink: #E2CBBE;
  --blue: #bacdbd;
  --tan: #f5f2e6;
  height: 100vh;
}

/* FONT STYLING */
h1 {
  font-family: Queensila;
  letter-spacing: 4px;
  font-size: 3em;
  text-align: center;
}

h2 {
  font-family: Gyahegi;
  font-weight: 2000;
  font-size: 1.8em;
}

h3 {
  font-family: Queensila;
  font-size: 1.8em;
  padding: 20px;
  font-weight: lighter;
}

.link {
  text-decoration: none;
  color: black;
}

/* NAV STYLING */

nav {
  position: sticky;
  top: 0px;
  z-index: 4;
  border: 2px solid;
  background-color: white;
  display: flex;
}

.logo {
  z-index: 3;
  padding: 8px 100px;
  border-right: 2px solid;
  background-color: var(--pink);
}

.routes {
  display: none;
  position: absolute;
  right: 50px;
}

.menu-closed {
  position: fixed;
  right: 50px;
  top: 30px;
}

.menu-open {
  position: absolute;
  width: 99.4%;
  padding-top: 50px;
  background-color: white;
  z-index: 5;
  border: 2px solid;
}

#root {
  background-color: var(--pink);
}

/* HOME STYLING */

.blurb {
  text-align: center;
  margin: auto;
  margin: 45px;
  margin-top: 30%;
}

.blurb-three {
  padding: 10%;
}

.blurb-three h3 {
  font-size: 1.6em;
}

.blurb h1{
  padding-left: 10px;
  font-size: 2em;
  font-weight: bolder;
}

.blurb h3 {
  text-align: center;
  font-size: 1.8em;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 0;
}

#button {
  font-size: 2em;
  font-family: Queensila;
  letter-spacing: 3px;
  padding: 20px;
  text-align: center;
  border: 2px solid black;
  background-color: white;
}

#button:hover {
  transform: scale(1.1);
}

/* FOOTER STYLING */

footer {
  text-align: center;
  padding: 10px;
}

footer h5 {
  color: black;
  font-weight: lighter;
  font-size: 1em;
}

/* WORK STYLING */

.projects h3 {
  z-index: 3;
  text-align: center;
}

.project-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}

.pink-box {
  background-color: rgb(221, 179, 219);
  margin: 5%;
  margin-top: 20px;
  z-index: 1;
}

.purple-box {
  background-color: rgb(179, 221, 192);
  margin: 5%;
  margin-top: 20px;
  z-index: 1;
}

.blue-box {
  background-color: rgb(212, 182, 229);
  margin: 5%;
  margin-top: 20px;
  z-index: 1;
}

.green-box {
  background-color: rgb(175, 215, 238); 
  margin: 5%;
  z-index: 1;
  margin-top: 20%;
}

.project-img {
  margin: 5%;
  margin-top: 0;
  width: 90%;
}

.project-img:hover {
  transform: scale(1.03);
}

.gradient-border {
  margin: 0 5%;
}

.text-box {
  margin: 2px;
  text-align: left;
  padding: 10px;
  z-index: 3;
  background-color: var(--pink);
}

.projects p {
  line-height: 20px;
  font-family: Queensila;
  font-size: 0.8em;
  letter-spacing: 0.8px;
}

/* ABOUT STYLING */

.about {
  display: flex;
  flex-direction: column;
}

.intro {
  display: flex;
  flex-direction: column;
}

.headshot {
  width: 100%;
  height: 650px;
  object-fit: cover;
}

.about h1 {
  margin-top: 15px;
  margin-bottom: 0;
  z-index: 2;
  font-size: 3.5em;
}

.about h2 {
  font-family: Queensila;
  font-size: 2.8em;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
}

.about h3 {
  text-align: center;
  margin-top: 15px;
}

.about li {
  font-family: Queensila;
  font-size: 1.3em;
  margin: 0 auto;
  line-height: 50px;
  letter-spacing: 1px;
  font-weight: bold;
  list-style-type: none;
}

.about span {
  font-size: 1.8em;
}

.about p {
  font-family: Queensila;
  font-size: 1.2em;
  letter-spacing: 2px;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 0 25px;
}

.skills-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.middle-column-one {
  background-color: white;
}

.middle-column-two {
  background-color: lightgray;
}

.right-column-three {
  background-color: black;
  color: white;
}

.middle-column-four {
  background-color: white;
}

@media only screen and (max-width: 768px) {

  .logo {
    padding: 8px 30px;
  }

  .headshot {
    height: 500px;
  }

  .mobile-column {
    height: 500px;
    border: 2px solid;
  }

  .mobile-column-small {
    padding: 0;
    height: 460px;
    border: 2px solid;
  }

  .mobile-column-smaller {
    height: 350px;
    border: 2px solid;
  }

  .blurb {
    margin-top: 25%;
  }

  .blurb h3{
    font-size: 1.3em;
  }

  .blurb-two h3 {
    font-size: 1.5em;
  }

  .blurb-three h3 {
    font-size: 1.3em;
  }

  .center {
    flex-direction: column;
  }

  .center #button {
    margin: 15px 25px;
  }
}
  
/* RESPONSIVE DESIGN */

@media only screen and (min-width: 768px) {
  h1 {
    letter-spacing: 4px;
    font-size: 3em;
  }

  h3 {
    font-size: 2em;
    padding: 0 20px;
  }

  /* NAV STYLING */

  .routes {
    display: flex;
  }

  .menu-closed {
    display: none;
  }

  /* HOME STYLING */


  .home-grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(4, 654px);
  }

  .left-column-one {
    margin: 0;
    padding: 0;
    border: 2px solid;
    grid-column: 1 / 4;
  }

  .middle-column-one {
    grid-column: 4 / 8;
    border: 2px solid;
    margin: 0;
    padding: 10%;
  }

  .right-column-one {
    grid-column: 8 / 10;
    border: 2px solid;
    margin: 0;
    padding: 0;
  }

  .left-column-two{
    border: 2px solid;
    grid-column: 1 / 3;
  }

  .middle-column-two {
    grid-column: 3 / 7;
    border: 2px solid;
    margin: 0;
    padding: 15%;
  }

  .right-column-two {
    grid-column: 7 / 10;
    border: 2px solid;
  }

  .left-column-three{
    margin: 0;
    padding: 0;
    border: 2px solid;
    grid-column: 1 / 5;
  }

  .middle-column-three {
    grid-column: 5 / 8;
    border: 2px solid;
  }

  .right-column-three {
    grid-column: 8 / 10;
    border: 2px solid black;
    margin: 0;
    padding: 0;
  }

  .left-column-four {
    margin: 0;
    padding: 0;
    border: 2px solid;
    grid-column: 1 / 4;
  }

  .middle-column-four {
    grid-column: 4 / 7;
    border: 2px solid;
  }

  .right-column-four {
    grid-column: 7 / 10;
    border: 2px solid black;
    margin: 0;
    padding: 0;
  }

  #button {
    padding: 20px;
    margin: 10px 40px;
  }

  /* WORK STYLING */

  .project-img {
    margin: 10%;
    margin-top: 0;
    margin-right: 20px;
    width: 400px;
  }

  .project-container {
    flex-direction: row;
    margin-top: 2%;
  }

  .blue-box {
    margin-left: 10%;
  }

  .green-box {
    margin-top: 10%;
    margin-left: 10%;
  }

  .gradient-border {
    background: none;
    margin: 0;
  }

  .text-box {
    padding: 5px 10px;
    margin-right: 10%;
    margin-top: 36%;
  }

  .green {
    margin-top: 75%;
  }

  .projects p {
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .github-link {
    font-size: 1.2em;
  }

  /* ABOUT STYLING */

  .intro {
    display: flex;
    flex-direction: row;
  }

  .intro-text {
    margin-top: 15%;
    margin-right: 2%;
  }




}

@media only screen and (min-width: 1024px) {

  /* ABOUT STYLING */

  .project-img {
    width: 600px;
  }

  .projects p {
    font-size: 1.5em;
    line-height: 1.5em;
  }

  .github-link {
    font-size: 1.4em;
  }


}

@media only screen and (min-width: 1500px) {

   /* ABOUT STYLING */

  .project-img {
    margin: 10%;
    margin-top: 0;
    width: 800px;
  }

  .project-container {
    flex-direction: row;
    margin-top: 2%;
  }

  .blue-box {
    margin-left: 10%;
  }

  .green-box {
    margin-top: 10%;
    margin-left: 10%;
  }

  .gradient-border {
    background: none;
    margin: 0;
  }

  .text-box {
    padding: 5px 10px;
    margin-right: 10%;
  }

  .about {
    margin: 0 8%;
  }

  .intro-text {
    margin-left: 5%;
    margin-right: 5%;
  }


}
